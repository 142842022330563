import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/400.css"
import "@fontsource/lato/400.css"
import { extendTheme } from "@chakra-ui/react"

export const webappThemeConfig = {
  fonts: {
    heading: "Montserrat",
    body: "Montserrat",
  },
  fontSizes: {
    xs: "0.563rem",
    sm: "0.75rem",
    md: "1rem",
    lg: "1.333rem",
    xl: "2.369rem",
    "2xl": "3.157rem",
    "3xl": "4.209rem",
  },
  shadows: {
    outline: "none",
  },
  colors: {
    blue: {
      primary: "#55a3de",
      dark: "#2170ac",
    },
    green: {
      500: "#84bd00",
      600: "#a6de23",
    },
    pink: {
      500: "#f8cbe2",
    },
    yellow: {
      500: "#fdda02",
    },
    primary: {
      500: "#292929",
    },
    black: {
      200: "#646464",
      100: "#282828",
      50: "#000000",
    },
    white: {
      50: "#FFFFFF",
      100: "#F9F9F9",
      200: "#EEEEEE",
    },
    grey: {
      500: "#C5C5C5",
      100: "#F6F5F4",
    },
  },

  components: {
    Text: {
      baseStyle: {
        fontFamily: "Lato",
        fontWeight: 400,
        lineHeight: 1.5,
      },
      variants: {
        blog: {
          width: "full",
          maxWidth: "700px",
          marginX: "auto",
          marginTop: 4,
        },
      },
      sizes: {
        lg: {
          fontSize: "lg",
        },
        md: {
          fontSize: "md",
        },
        sm: {
          fontSize: "sm",
        },
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: "bold",
      },
      sizes: {
        "2xl": {
          fontFamily: "Montserrat",
          fontSize: { base: "lg", md: "2xl" },
          fontWeight: 700,
        },
        xl: {
          fontSize: "xl",
        },
        lg: {
          fontFamily: "Montserrat",
          fontSize: "lg",
          fontWeight: 700,
        },
        md: {
          fontFamily: "Lato",
          fontSize: "md",
          fontWeight: 400,
          lineHeight: 2,
        },
        sm: {
          fontFamily: "Lato",
          fontSize: "sm",
          fontWeight: 600,
          lineHeight: 1.5,
        },
      },
    },
    Container: {
      baseStyle: {
        maxWidth: {
          base: "calc(100vw - 2rem)",
          md: "min(1400px, calc(100vw - 8rem))",
        },
        padding: 0,
      },
    },
    FormLabel: {
      baseStyle: {
        fontFamily: "Montserrat",
        fontWeight: 700,
      },
      sizes: {
        lg: {
          fontSize: "lg",
        },
      },
    },
    Button: {
      baseStyle: {
        marginLeft: "auto",
      },
    },
  },
}

const theme = extendTheme(webappThemeConfig)

export default theme
