import React, { useCallback, useEffect } from "react"
import {
  useSetRecoilState
} from "recoil"
import { mouseAtom } from "../atoms/mouse"
import { scrollAtom } from "../atoms/scroll"

const globalStyle = `
html {
  font-size: 16px;
}

@media screen and (min-width: 1920px) {
  html {
    font-size: 18px;
  }
}
`

const GlobalUIControls: React.FC = () => {
  const setScroll = useSetRecoilState(scrollAtom)
  const setMouse = useSetRecoilState(mouseAtom)
  const onScroll = useCallback(() => {
    setScroll(window.scrollY)
  }, [setScroll])

  const onMouseMove = useCallback((event: MouseEvent) => {
    setMouse({ x: event.clientX, y: event.clientY })
  }, [setScroll])

  useEffect(() => {
    window.addEventListener("scroll", onScroll)
    window.addEventListener("mousemove", onMouseMove)
    setScroll(window.scrollY)
    return () => {
      window.removeEventListener("scroll", onScroll)
      window.removeEventListener("mousemove", onMouseMove)
    }
  }, [])

  return <style>{globalStyle}</style>
}

export default React.memo(GlobalUIControls)