import { ChakraProvider } from "@chakra-ui/react"
import type { GatsbyBrowser } from "gatsby"
import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import {
  RecoilRoot
} from "recoil"
import theme from "./src/@chakra-ui/gatsby-plugin/theme"
import GlobalUIControls from "./src/components/GlobalUIControls"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element, props }) => {

  return (
    <ChakraProvider theme={theme}>
      <RecoilRoot>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY ?? ""}
        >
          <GlobalUIControls />
          {element}
        </GoogleReCaptchaProvider>
      </RecoilRoot>
    </ChakraProvider>
  )
}